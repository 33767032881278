const angleUnitRegex = /^-?[0-9.]+(deg|rad|grad|turn)$/;

/**
 * Get the angle unit. Assumes that if the angle is a number, it is in degrees
 * @param angle - A valid CSS angle string or a number
 * @returns The angle CSS unit
 */
export function getAngleUnit(angle: CssAngle | number): CssAngleUnit {
  if (typeof angle === 'number') return 'deg';
  const matches = angle.match(angleUnitRegex);
  if (!matches?.[1]) {
    throw new Error(`Could not find angle unit, invalid angle: ${angle}`);
  }
  return matches[1] as CssAngleUnit;
}

/**
 * Convert an angle into a number in Radian unit.
 * @param angle - A valid CSS angle string or a number
 * @returns The angle value in Radian
 */
export function toRadians(angle: CssAngle | number): number {
  const unit = getAngleUnit(angle);
  const value = typeof angle === 'number' ? angle : parseFloat(angle);
  switch (unit) {
    case 'deg':
      return (value * Math.PI) / 180;
    case 'rad':
      return value;
    case 'grad':
      return (value * Math.PI) / 200;
    case 'turn':
      return value * 2 * Math.PI;
  }
}
